<template>
    <div class="row d-flex flex-wrap" role="group">
        <div class="col">
            <template v-if="this.type == 'combo'">
                <v-combobox
                    v-model="model"
                    :items="arreglo"
                    :item-text="itemText"
                    :item-value="itemValue"
                    :outlined="outlined"
                    dense
                    :label="label"
                    @change="emitSelectedValue"
                    :multiple="multiple"
                    :key="contador"
                    :rules="computeFieldRules()"
                    :clearable="clearable"
                ></v-combobox>
            </template>
            <template v-else>
                <v-combobox
                    v-model="model"
                    :items="arreglo"
                    :item-text="itemText"
                    :item-value="itemValue"
                    :outlined="outlined"
                    dense
                    :label="label"
                    @change="emitSelectedValue"
                    :multiple="multiple"
                    :key="contador"
                    :rules="computeFieldRules()"
                    :clearable="clearable"
                > 
                    <template slot="selection" slot-scope="{item, index}">
                        <span v-if="index < 1">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <span v-for="t in model">
                                            <span v-if="t.length > 25">
                                                {{ item[itemValue].substring(0, 25) }}...
                                            </span>
                                            <span v-else>
                                                {{ item[itemValue] }}
                                            </span>
                                        </span>
                                    </span>
                                </template>
                                <span>
                                    {{ item[itemValue] }}
                                </span>
                            </v-tooltip>
                        </span>
                        <span v-else>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-if="index === 1" class="text-grey text-caption align-self-center" v-bind="attrs" v-on="on">
                                        &nbsp;(+{{ model.length - 1 }} {{ labelTooltip }})
                                    </span>
                                </template>
                                <span v-for="t in model">
                                    {{ t[itemValue] }},
                                </span>
                            </v-tooltip>
                        </span>
                    </template>
                    <template slot='item' slot-scope='{ item }'>
                        {{ item[itemValue] }}
                    </template>
                </v-combobox>
            </template>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        props: {
            url: {
                type: String
            },
            urlExterna: {
                type: String,
                default: null
            },
            label: {
                type: String,
                required: true
            },
            multiple: {
                type: Boolean,
                default: false
            },
            itemValue: {
                type: String,
                default: 'id'
            },
            itemText: {
                type: String,
                default: 'nombre'
            },
            valor: {
                type: [Object, String, Array, Number]
            },
            valorMultiple: {
                type: Array
            },
            editable: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: 'select'
            },
            required: {
                type: Boolean,
                default: false
            },
            data: {
                type: Array
            },
            selectedFirst: {
                type: Boolean,
                default: false
            },
            clearable: {
                type: Boolean,
                default: false
            },
            outlined: {
                type: Boolean,
                default: true
            },
            labelTooltip: {
                type: String,
                default: ''
            },
        },
        data() {
            return {
                rules: {
                    required: (v) => v && v.id != undefined || "Este campo es obligatorio."
                },
                modelo: [],
                arreglo: [],
                contador: 0,
                estado: 0,
                editado: false
            }
        },
        methods: {
            getValue() {
                return this.modelo;
            },
            setValue(value) {
                this.modelo = value;
            },
            async getData() {
                if (this.data) {
                    this.arreglo = this.data
                } else {
                    const params = {
                        rowsPerPage: -1,
                        activo: 'y'
                    };
                    
                    if (this.urlExterna) {
                        fetch(this.urlExterna)
                        .then(response => response.json())
                        .then(data => {
                            this.arreglo = data
                        })
                    } else {
                        await axios.get(process.env.VUE_APP_BACKEND_URL + this.url, { params })
                        .then((response) => {
                            this.arreglo.push(...response.data.items)
                            //this.arreglo = response.data.items;
                        }).catch((error) => {
                            console.error(error)
                        })
                    }
                }
            },
            emitSelectedValue() {
                if (!this.multiple) {
                    if (!this.arreglo.includes(this.model)) {
                        this.model = [];
                        return
                    }
                } else {
                    //this.model = this.model.filter(item => this.arreglo.includes(item));
                    this.$emit("selected-rca", this.model)
                }
                if (typeof this.modelo !== 'object' || this.modelo === null) {
                    this.modelo = ''
                }
                if (this.type == 'regiones' || this.type == 'comunas') {
                    this.$emit("selected-region", this.modelo);
                } else {
                    this.$emit("selected-value", this.modelo);
                }
            },
            /* edit(item) {
                this.$refs.titularRef.open(item, false)
            }, */
            reload(value) {
                this.contador = value
                this.getData()
            },
            computeFieldRules() {
                return this.required ? [this.rules.required] : [];
            },
            getEditado() {
                return this.editado
            },
            setEditado(editado) {
                this.editado = editado
            }
        },
        computed: {
            model: {
                get: function() {
                    return this.modelo
                },
                set: function(newValue) {
                    let compare = ''
                    if (this.valor) {
                        compare = this.valor
                    } else if (this.valorMultiple) {
                        compare = this.valorMultiple
                    }
                    /* if (JSON.stringify(compare) != JSON.stringify(newValue)) {
                        if ((JSON.stringify(this.model) && JSON.stringify(this.model) != '') && (newValue && newValue != '')) {
                            this.editado = true
                        }
                    } */
                    if (this.multiple) {
                        newValue.forEach(element => {
                            const index = newValue.indexOf(element);
                            if (!this.arreglo.some(e => e.nombre === element.nombre)) {
                                newValue.splice(index, 1);
                            }
                        });
                    }
                    this.modelo = newValue;
                }
            },
        },
        mounted() {
            this.getData()
            if (this.valor) {
                this.model = this.valor
            }
            if (this.valorMultiple) {
                this.model = this.valorMultiple
            }
        },
        updated() {
            if (this.selectedFirst && this.arreglo && this.arreglo.length == 1) {
                this.modelo = this.arreglo[0]
            }
        },
    }
</script>
